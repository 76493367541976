
export default class Modal {

  id: string;

  constructor(id: string) {
    this.id = id ;
  }

  bind(name: string) {
    let modal = this;
    Array.prototype.forEach.call(document.querySelectorAll(name),  e => {
      e.addEventListener('click', function() {
        modal.show();
      });
    })
  }

  show() {
    document.getElementById(this.id).style.display = 'block';
    document.body.classList.add("open-modal");
  }

  hide() {
    document.getElementById(this.id).style.display = 'none';
    document.body.classList.remove("open-modal");
  }
}
