var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.student_courses.length > 0
    ? _c("div", { staticClass: "widget mini-student" }, [
        _c("div", { staticClass: "widget__title" }, [
          _vm._v("さいきんクリアしたすごい子たち")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget__inner" }, [
          _c(
            "ul",
            { staticClass: "item-list" },
            _vm._l(_vm.student_courses, function(student_course) {
              return _c("li", [
                _c("div", { staticClass: "item-header" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(student_course.student.screen_name))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "course" }, [
                    _c(
                      "span",
                      {
                        class:
                          "category-color-" +
                          student_course.course.category.code
                      },
                      [_vm._v("●")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "/courses/" + student_course.course.id }
                      },
                      [_vm._v(_vm._s(student_course.course.title))]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-image" }, [
                  _c("img", {
                    attrs: {
                      src: student_course.student.icon.small.url,
                      width: "50"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-more" }, [
                  _c(
                    "a",
                    {
                      staticClass: "mod_btn-more btn-s",
                      attrs: {
                        href: "/students/" + student_course.student.login_name
                      }
                    },
                    [_vm._v("しまを見る")]
                  )
                ])
              ])
            })
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3f6f51f9", { render: render, staticRenderFns: staticRenderFns })
  }
}