















import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
 props: ['category_id'],
  data() {
    return {
      category: null,
      courses: []
    }
  },
  mounted() {
    this.get_courses();
  },
  methods: {
    get_courses() {
      let self = this;
      axios.get(`/api/categories/${this.category_id}/recent_published_courses`).then( res =>  {
        self.courses = res.data.courses;
        self.category = res.data.category;
      });
    }
  }
});
