var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "widget signup" }, [
      _c("div", { staticClass: "widget__inner" }, [
        _c("a", { attrs: { href: "/students/new" } }, [
          _c("img", {
            attrs: {
              src: "/statics/images/banners/bnr_lets_quest_side.svg",
              alt: "ぼうけんしよう！"
            }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a46f3e80", { render: render, staticRenderFns: staticRenderFns })
  }
}