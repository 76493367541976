var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.recent_heart_up_courses.length > 0
    ? _c("div", { staticClass: "widget ranking" }, [
        _c("div", { class: "widget__title category-" + _vm.category.code }, [
          _vm._v(_vm._s(_vm.category.name) + "のさいきん人気のぼうけん")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget__inner" }, [
          _c(
            "ul",
            { staticClass: "ranking__list" },
            _vm._l(_vm.recent_heart_up_courses, function(course) {
              return _c("li", [
                _c("span", { class: "category-color-" + _vm.category.code }, [
                  _vm._v("●")
                ]),
                _c("a", { attrs: { href: "/courses/" + course.id } }, [
                  _vm._v(_vm._s(course.title))
                ])
              ])
            })
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4b599f10", { render: render, staticRenderFns: staticRenderFns })
  }
}