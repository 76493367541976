var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "course-index-wrapper" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "course-group" },
        _vm._l(_vm.recommend_courses, function(course) {
          return _c("course_box", {
            key: course.id,
            attrs: {
              current_student_info: _vm.current_student_info,
              course: course
            }
          })
        })
      ),
      _vm._v(" "),
      _vm._m(1)
    ]),
    _vm._v(" "),
    _vm.recent_notes.length > 0
      ? _c("div", { staticClass: "course-index-wrapper" }, [
          _vm._m(2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-note-group" },
            _vm._l(_vm.recent_notes, function(recent_note) {
              return _c(
                "a",
                {
                  staticClass: "p-note",
                  attrs: { href: "/notes/" + recent_note.id }
                },
                [
                  _c("div", { staticClass: "p-note__header" }, [
                    _c("div", { staticClass: "p-note__icon" }, [
                      _c("span", { staticClass: "icon-image" }, [
                        _c("img", {
                          attrs: {
                            src:
                              "/statics/images/note/icon_note_" +
                              recent_note.note_type +
                              ".png",
                            alt: recent_note.note_type,
                            width: "50"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      recent_note.note_type === "knowledge"
                        ? _c("span", { staticClass: "icon-text" }, [
                            _vm._v("まめ知識")
                          ])
                        : recent_note.note_type === "question"
                          ? _c("span", { staticClass: "icon-text" }, [
                              _vm._v("考えてみよう")
                            ])
                          : recent_note.note_type === "challenge"
                            ? _c("span", { staticClass: "icon-text" }, [
                                _vm._v("やってみよう")
                              ])
                            : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "p-note__main" }, [
                    _c("div", { staticClass: "p-note__title" }, [
                      _vm._v(_vm._s(recent_note.title))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "p-note__footer" }, [
                    _c("div", { staticClass: "p-note__category-wrapper" }, [
                      _c(
                        "div",
                        {
                          class:
                            "p-note__category category-" +
                            recent_note.category.code
                        },
                        [_vm._v(_vm._s(recent_note.category.name))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "p-note__teacher" }, [
                        _c("img", {
                          staticClass: "icon-teacher",
                          attrs: {
                            src: recent_note.teacher.icon.url,
                            width: "24",
                            alt: ""
                          }
                        }),
                        _vm._v(_vm._s(recent_note.teacher.name))
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(3, true)
                  ])
                ]
              )
            })
          ),
          _vm._v(" "),
          _vm._m(4)
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.recent_heart_up_courses.length > 0
      ? _c("div", { staticClass: "course-index-wrapper" }, [
          _vm._m(5),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "course-group" },
            _vm._l(_vm.recent_heart_up_courses, function(course) {
              return _c("course_box", {
                key: course.id,
                attrs: {
                  current_student_info: _vm.current_student_info,
                  course: course
                }
              })
            })
          ),
          _vm._v(" "),
          _vm._m(6)
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.recent_heart_up_teachers.length > 0
      ? _c("div", { staticClass: "course-index-wrapper" }, [
          _vm._m(7),
          _vm._v(" "),
          _c("div", { staticClass: "course-group" }, [
            _c(
              "div",
              { staticClass: "teacher-list" },
              _vm._l(_vm.recent_heart_up_teachers, function(teacher) {
                return _c("div", { staticClass: "teacher" }, [
                  _c(
                    "a",
                    {
                      staticClass: "icon-teacher",
                      attrs: { href: "/teachers/" + teacher.id }
                    },
                    [
                      _c("div", { staticClass: "photo" }, [
                        _c("img", { attrs: { src: teacher.icon.small.url } })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(teacher.name))
                      ])
                    ]
                  )
                ])
              })
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "course-index-wrapper" }, [
      _vm._m(8),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "course-group" },
        _vm._l(_vm.recent_published_courses, function(course) {
          return _c("course_box", {
            key: course.id,
            attrs: {
              current_student_info: _vm.current_student_info,
              course: course
            }
          })
        })
      ),
      _vm._v(" "),
      _vm._m(9)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mod-course-title" }, [
      _c("div", { staticClass: "mod-course-title__icon" }, [
        _c("img", {
          attrs: {
            src: "/statics/images/icon/icon_ttl_yacht.svg",
            width: "56",
            height: "56",
            alt: ""
          }
        })
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "mod-course-title__name" }, [
        _vm._v("今週のおすすめぼうけん")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "more" }, [
      _c(
        "a",
        {
          staticClass: "btn-more-l",
          attrs: { href: "/courses/recommend_courses" }
        },
        [_vm._v("もっとみる")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mod-course-title" }, [
      _c("div", { staticClass: "mod-course-title__icon" }, [
        _c("img", {
          attrs: {
            src: "/statics/images/icon/icon_ttl_yacht.svg",
            width: "56",
            height: "56",
            alt: ""
          }
        })
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "mod-course-title__name" }, [
        _vm._v("あたらしい先生ノート")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn btn-primary btn-border btn-block" }, [
      _c("img", {
        attrs: {
          src: "/statics/images/note/btn_view_note.png",
          width: "114",
          alt: "ノートをみる"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "more" }, [
      _c("a", { staticClass: "btn-more-l", attrs: { href: "/notes/" } }, [
        _vm._v("もっとみる")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mod-course-title" }, [
      _c("div", { staticClass: "mod-course-title__icon" }, [
        _c("img", {
          attrs: {
            src: "/statics/images/icon/icon_ttl_submarine.svg",
            width: "56",
            height: "56",
            alt: ""
          }
        })
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "mod-course-title__name" }, [
        _vm._v("さいきん人気のぼうけん")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "more" }, [
      _c(
        "a",
        {
          staticClass: "btn-more-l",
          attrs: { href: "/courses/recent_heart_up_courses" }
        },
        [_vm._v("もっとみる")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mod-course-title" }, [
      _c("div", { staticClass: "mod-course-title__icon" }, [
        _c("img", {
          attrs: {
            src: "/statics/images/icon/icon_ttl_whale.svg",
            width: "56",
            height: "56",
            alt: ""
          }
        })
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "mod-course-title__name" }, [
        _vm._v("さいきん人気のせんせい")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mod-course-title" }, [
      _c("div", { staticClass: "mod-course-title__icon" }, [
        _c("img", {
          attrs: {
            src: "/statics/images/icon/icon_ttl_dolphin.svg",
            width: "56",
            height: "56",
            alt: ""
          }
        })
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "mod-course-title__name" }, [
        _vm._v("あたらしいぼうけん")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "more" }, [
      _c(
        "a",
        {
          staticClass: "btn-more-l",
          attrs: { href: "/courses/recent_published_courses" }
        },
        [_vm._v("もっとみる")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7852da53", { render: render, staticRenderFns: staticRenderFns })
  }
}