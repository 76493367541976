var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aniFadeIn" }, [
    _c("aside", { ref: "side_content", staticClass: "side-content" }, [
      _vm.fakead
        ? _c("div", { staticClass: "widget-banner" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      !_vm.fakead
        ? _c("div", { staticClass: "widget-banner" }, [
            _c("ins", {
              staticClass: "adsbygoogle",
              staticStyle: {
                display: "inline-block",
                width: "300px",
                height: "250px"
              },
              attrs: {
                "data-ad-client": "ca-pub-1349469100763380",
                "data-ad-slot": "4734025852"
              }
            })
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          href: "https://itunes.apple.com/jp/app//id1255034111?mt=8",
          target: "_blank"
        }
      },
      [
        _c("img", {
          attrs: {
            src: "/statics/images/banners/bnr_anipals.png",
            width: "350",
            alt: ""
          }
        })
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-75344778", { render: render, staticRenderFns: staticRenderFns })
  }
}