var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "widget categories" }, [
    _c("div", { staticClass: "widget__title" }, [_vm._v("かもく")]),
    _vm._v(" "),
    _c("div", { staticClass: "widget__inner" }, [
      _c(
        "div",
        { staticClass: "categories__list" },
        [
          _vm._l(_vm.categories, function(category) {
            return _c(
              "a",
              {
                class: "category-" + category.code,
                attrs: { href: "/categories/" + category.id }
              },
              [_vm._v("\n        " + _vm._s(category.name) + "\n      ")]
            )
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "category-undefined",
              attrs: { href: "/series_pages/" }
            },
            [_vm._v("シリーズ")]
          )
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-50950bc4", { render: render, staticRenderFns: staticRenderFns })
  }
}