var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "widget my-island" }, [
      _c("div", { staticClass: "widget__inner" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-border btn-block",
            attrs: { href: "/students/" + _vm.student.login_name }
          },
          [_vm._v("ぼくわたしのしま")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "widget profile" }, [
      _c("div", { staticClass: "widget__inner" }, [
        _c("div", { staticClass: "profile__name-group" }, [
          _c("img", {
            staticClass: "profile__icon",
            attrs: { src: _vm.student.icon.url }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "profile__name" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.student.screen_name) + "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profile__money" }, [
          _c("span", { staticClass: "icon" }, [_vm._v("ゴールド")]),
          _c("span", { staticClass: "gold-holder" }, [
            _vm._v(_vm._s(_vm.student.gold))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profile__money" }, [
          _vm._m(1),
          _c("span", { staticClass: "ticket-holder" }, [
            _vm._v(_vm._s(_vm.student.ticket_count))
          ])
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-border btn-block",
            attrs: { href: "/students/" + _vm.student.login_name + "/monsters" }
          },
          [_vm._v("モンスター図鑑")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-border btn-block",
            attrs: { href: "/mypage/friends/" }
          },
          [_vm._v("フレンドめいぼ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-border btn-block",
            attrs: { href: "/mypage/ilands/edit" }
          },
          [_vm._v("しまづくり")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-border btn-block",
            attrs: { href: "/mypage/ilands/shop" }
          },
          [_vm._v("しまショップ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-border btn-block",
            attrs: { href: "/mypage/users/edit_icon" }
          },
          [_vm._v("マイキャラのへんこう")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-border btn-block",
            attrs: { href: "/mypage/users/edit" }
          },
          [_vm._v("せってい")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-border btn-block",
            attrs: { href: "/mypage/guardians/" }
          },
          [_vm._v("保護者向け設定")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-center" }, [
      _c("img", {
        attrs: { src: "/statics/images/categories/icon_my_island_01.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", {
        attrs: {
          src: "/statics/images/icon/icon_ticket.png",
          width: "55",
          alt: "チケット"
        }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-17116063", { render: render, staticRenderFns: staticRenderFns })
  }
}