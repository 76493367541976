var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "widget friend-search" }, [
      _c("div", { staticClass: "widget__inner" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _vm._v("フレンドの数・・・" + _vm._s(_vm.friend_count) + "人")
        ]),
        _vm._v(" "),
        !_vm.is_max_friend
          ? _c("div", { staticClass: "mb-1" }, [
              _vm._v("あたらしいフレンドを探す")
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.is_max_friend
          ? _c("form", { staticClass: "form" }, [
              _vm.on_not_found
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v("みつからなかったよ。")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.friend_login_name,
                    expression: "friend_login_name"
                  }
                ],
                staticClass: "form-control  mb-1",
                attrs: { type: "text", placeholder: "けんさく" },
                domProps: { value: _vm.friend_login_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.friend_login_name = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-border btn-block",
                  attrs: { type: "button" },
                  on: { click: _vm.search }
                },
                [_vm._v("さがす")]
              )
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _vm.invitations.length > 0
      ? _c("div", { staticClass: "widget friend-recommend" }, [
          _c("div", { staticClass: "widget__title" }, [
            _vm._v("フレンドのおうぼ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "widget__inner" }, [
            _c(
              "ul",
              { staticClass: "item-list" },
              _vm._l(_vm.invitations, function(invitation) {
                return _c("li", [
                  _c("div", { staticClass: "item-header" }, [
                    _c("span", { staticClass: "item-student-name" }, [
                      _c("span", [
                        _vm._v(_vm._s(invitation.student.screen_name))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "item-student-id" }, [
                      _c("span", [
                        _vm._v(_vm._s(invitation.student.login_name))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-image" }, [
                    _c("img", {
                      staticClass: "friend-recommend__icon",
                      attrs: {
                        src: invitation.student.icon.small.url,
                        width: "50"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-student-message" }, [
                    _vm._v("\n            フレンドになろうよ！\n          ")
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "item-btn-list" }, [
                    _c("li", [
                      _c(
                        "button",
                        {
                          staticClass: "mod_btn btn-normal",
                          on: {
                            click: function($event) {
                              _vm.accept(invitation.id)
                            }
                          }
                        },
                        [_vm._v("はい")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "button",
                        {
                          staticClass: "mod_btn btn-cancel",
                          on: {
                            click: function($event) {
                              _vm.reject(invitation.id)
                            }
                          }
                        },
                        [_vm._v("いいえ")]
                      )
                    ])
                  ])
                ])
              })
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "friend-search__icons" }, [
      _c("img", {
        attrs: { src: "/statics/images/mob/mob_001.svg", height: "30" }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/statics/images/mob/mob_002.svg", height: "30" }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/statics/images/mob/mob_003.svg", height: "30" }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6384c746", { render: render, staticRenderFns: staticRenderFns })
  }
}