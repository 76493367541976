






























































































































import axios from 'axios';
import Vue from 'vue';
import Modal from './../../../packs/modal';

export default Vue.extend({
  props: ['course_id','article_id', 'course_title', 'article_title' ],
  data() {
    return {
      errors: [],
      security_report_type: null,
      name: null,
      email: null,
      title: null,
      message: null,
      modal: new Modal("security_report-modal"),
      modal_done: new Modal("security_report_done-modal")
    }
  },
  mounted() {
    this.$forceUpdate();
  },
  updated() {
    this.modal.bind(".open-security_report-modal");
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();

      this.errors = [];

      if (!this.security_report_type) {
        this.errors.push('違法種別を選択してください');
      }

      if (!this.name) {
        this.errors.push('名前を入力してください');
      } else if (this.name.length > 50 ) {
        this.errors.push('名前は50文字以内におさめてください');
      }

      if (!this.email) {
        this.errors.push('メールアドレスを入力してください'); } else if (!this.validEmail(this.email)) {
        this.errors.push('メールアドレスを入力してください');
      }

      if (!this.title) {
        this.errors.push('タイトルを入力してください');
      } else if (this.title.length > 100 ) {
        this.errors.push('タイトルは100文字以内におさめてください');
      }

      if (!this.message) {
        this.errors.push('メッセージを入力してください');
      } else if (this.message.length > 5000 ) {
        this.errors.push('メッセージは5000文字以内におさめてください');
      }

      if (this.errors.length > 0 ) {
        return ;
      }

      let self = this;
      let params = {
        security_report_type: this.security_report_type,
        name: this.name,
        email: this.email,
        title: this.title,
        message: this.message,
        course_id: this.course_id,
        article_id: this.article_id
      };

      axios.post('/api/security_reports', params).then(function(response) {
        self.modal.hide();
        self.modal_done.show();
      });

    },
    validEmail: function (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

  }
});
