var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.series_page
    ? _c("div", { staticClass: "widget mini-student" }, [
        _c("div", { staticClass: "widget__title" }, [
          _vm._v("シリーズピックアップ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget__inner" }, [
          _c("a", { attrs: { href: "/series_pages/" + _vm.series_page.id } }, [
            _c("img", {
              attrs: { src: _vm.series_page.image.medium.url, width: "100%" }
            }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.series_page.title))])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7d834142", { render: render, staticRenderFns: staticRenderFns })
  }
}