


























import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({

 data() {
    return {
      student_courses: []
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get(`/api/students/recent_done_students`).then( res =>  {
        this.student_courses = res.data.student_courses;
      });
    }
  }
});
