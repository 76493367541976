var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.course.status == null || _vm.course.status == "active"
    ? _c(
        "a",
        {
          staticClass: "course",
          attrs: { href: _vm.course_path(_vm.course.id) }
        },
        [
          _c(
            "div",
            {
              staticClass: "course__header",
              class: ["category-" + _vm.course.category.code]
            },
            [
              _c("div", { staticClass: "title-tip" }, [
                _c("span", { staticClass: "cat" }, [
                  _vm._v(_vm._s(_vm.course.category.name))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "heart" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.course.heart_count))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.course.title))])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "course__main" }, [
            _c("div", { staticClass: "course__thumbs" }, [
              _c("div", { staticClass: "thumbs" }, [
                _c("img", { attrs: { src: _vm.course.image.url } })
              ]),
              _vm._v(" "),
              _vm.studeint_progress() == "done"
                ? _c("div", { staticClass: "status" }, [
                    _c("img", {
                      attrs: {
                        src: "/statics/images/icon/status_done.svg",
                        width: "64",
                        height: "64",
                        alt: "よくできました"
                      }
                    })
                  ])
                : _vm.studeint_progress() == "playing"
                  ? _c("div", { staticClass: "status" }, [
                      _c("img", {
                        attrs: {
                          src: "/statics/images/icon/status_playing.svg",
                          width: "64",
                          height: "64",
                          alt: "べんきょうちゅう"
                        }
                      })
                    ])
                  : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "course__description" }, [
              _vm._v(_vm._s(_vm.course.goal))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "course__footer" }, [
            _c(
              "div",
              { staticClass: "course__difficulty" },
              [
                _vm.course.grade && _vm.course.grade == "hiyoko_grade"
                  ? _c("div", { staticClass: "balloon" }, [
                      _c("p", [_vm._v("ぴよぴよ")])
                    ])
                  : _c("div", { staticClass: "balloon" }, [
                      _c("p", [_vm._v(_vm._s(_vm.course.difficulty_i18n))])
                    ]),
                _vm._v(" "),
                _vm.course.grade && _vm.course.grade == "hiyoko_grade"
                  ? _c("div", { staticClass: "difficulty_monstars" }, [
                      _c("img", {
                        attrs: {
                          src: "/statics/images/difficulty/hiyoko.png",
                          height: "26"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: "/statics/images/difficulty/hiyoko.png",
                          height: "26"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: "/statics/images/difficulty/hiyoko.png",
                          height: "26"
                        }
                      })
                    ])
                  : _c("difficulty_monstars", {
                      attrs: { difficulty: _vm.course.difficulty }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(1)
          ])
        ]
      )
    : _c("div", { staticClass: "course" }, [
        _c(
          "div",
          {
            staticClass: "course__header",
            class: ["category-" + _vm.course.category.code]
          },
          [
            _c("div", { staticClass: "title-tip" }, [
              _c("span", { staticClass: "cat" }, [
                _vm._v(_vm._s(_vm.course.category.name))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "heart" }, [
                _c("span", { staticClass: "icon" }),
                _vm._v(" "),
                _c("span", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.course.heart_count))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.course.title))])
          ]
        ),
        _vm._v(" "),
        _vm._m(2)
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", {
        attrs: {
          src: "/statics/images/icon/icon_heart_on.svg",
          width: "20",
          height: "20",
          alt: "ハート"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn btn-primary btn-border btn-block" }, [
      _c("img", {
        attrs: {
          src: "/statics/images/courses/btn_challenge.svg",
          width: "103",
          height: "21",
          alt: "チャレンジ！"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "course__main" }, [
      _c("div", { staticClass: "course__making" }, [
        _c("img", {
          attrs: { src: "/statics/images/icon/icon_making.svg", alt: "作成中" }
        })
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0eb1b176", { render: render, staticRenderFns: staticRenderFns })
  }
}