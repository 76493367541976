
































import axios from 'axios';
import Vue from 'vue';

export default Vue.extend({
 data() {
    return {
      teachers: []
    }
  },
  mounted() {
    this.getTeachers();
  },
  methods: {
    getTeachers() {
      axios.get(`/api/teachers/newbies`).then( res =>  {
        this.teachers = res.data.teachers;
      });
    }
  }
});

