var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.friend_assignments.length > 0
      ? _c("div", { staticClass: "widget mini-student" }, [
          _c("div", { staticClass: "widget__title" }, [
            _vm._v("きょうのしゅくだいやったフレンド")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "widget__inner" }, [
            _c(
              "ul",
              { staticClass: "item-list" },
              _vm._l(_vm.friend_assignments, function(item) {
                return _c("li", [
                  _c("div", { staticClass: "item-header" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.student.screen_name))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "course" }, [
                      _c(
                        "span",
                        {
                          class: "category-color-" + item.course.category.code
                        },
                        [_vm._v("●")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        { attrs: { href: "/courses/" + item.course.id } },
                        [_vm._v(_vm._s(item.course.title))]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-image" }, [
                    _c("img", {
                      attrs: { src: item.student.icon.small.url, width: "50" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-more" }, [
                    _c(
                      "a",
                      {
                        staticClass: "mod_btn-more btn-s",
                        attrs: { href: "/students/" + item.student.login_name }
                      },
                      [_vm._v("しまを見る")]
                    )
                  ])
                ])
              })
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.friend_articles.length > 0
      ? _c("div", { staticClass: "widget mini-student" }, [
          _c("div", { staticClass: "widget__title" }, [
            _vm._v("きょうのクエストやったフレンド")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "widget__inner" }, [
            _c(
              "ul",
              { staticClass: "item-list" },
              _vm._l(_vm.friend_articles, function(item) {
                return _c("li", [
                  item.score == 10
                    ? _c("div", { staticClass: "bg" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-header" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.student.screen_name))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "course" }, [
                      _c(
                        "span",
                        {
                          class: "category-color-" + item.course.category.code
                        },
                        [_vm._v("●")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        { attrs: { href: "/courses/" + item.course.id } },
                        [_vm._v(_vm._s(item.course.title))]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-image" }, [
                    _c("img", {
                      attrs: { src: item.student.icon.small.url, width: "50" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-more" }, [
                    _c(
                      "a",
                      {
                        staticClass: "mod_btn-more btn-s",
                        attrs: { href: "/students/" + item.student.login_name }
                      },
                      [_vm._v("しまを見る")]
                    )
                  ])
                ])
              })
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.friend_exams.length > 0
      ? _c("div", { staticClass: "widget mini-student" }, [
          _c("div", { staticClass: "widget__title" }, [
            _vm._v("きょうの冒険クリアフレンド")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "widget__inner" }, [
            _c(
              "ul",
              { staticClass: "item-list" },
              _vm._l(_vm.friend_exams, function(item) {
                return _c("li", [
                  item.score == 10
                    ? _c("div", { staticClass: "bg" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-header" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.student.screen_name))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "course" }, [
                      _c(
                        "span",
                        {
                          class: "category-color-" + item.course.category.code
                        },
                        [_vm._v("●")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        { attrs: { href: "/courses/" + item.course.id } },
                        [_vm._v(_vm._s(item.course.title))]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-image" }, [
                    _c("img", {
                      attrs: { src: item.student.icon.small.url, width: "50" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-more" }, [
                    _c(
                      "a",
                      {
                        staticClass: "mod_btn-more btn-s",
                        attrs: { href: "/students/" + item.student.login_name }
                      },
                      [_vm._v("しまを見る")]
                    )
                  ])
                ])
              })
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-fddf1e94", { render: render, staticRenderFns: staticRenderFns })
  }
}