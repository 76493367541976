



















import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      courses: []
    }
  },
  mounted() {
    this.get_courses();
  },
  methods: {
    get_courses() {
      axios.get(`/api/courses/recent_published_courses`,{params: { limit: 5 } }).then( res =>  {
        this.courses = res.data.courses;
      });
    }
  }
});
