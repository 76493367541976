import Vue from 'vue';
import ListVue from '../../src/components/root/courses.vue';
import Sidebar from '../components/sidebar.ts';
import Swiper from '../components/swiper.js';


document.addEventListener('DOMContentLoaded', () => {
});

document.addEventListener('readyAuthorizer', (e) => {
  var isLogin =  (<CustomEvent>event).detail.is_login;
  var student = (<CustomEvent>event).detail.student;
  var sidebar = new Sidebar(isLogin,student);
  sidebar.setupTopSidebar();
  setCourses(isLogin,student);
});

function setCourses(isLogin,student) {
  var vue = new Vue(ListVue);
  if( isLogin) {
    vue.$data.current_student = student;
  }
  vue.$mount("#app-course-list");
}

var mySwiper = new Swiper ('.swiper-container', {
  // Optional parameters
  loop: true,
  slidesPerView: 1,
  spaceBetween: 10,
  centeredSlides : true,
  speed: 1000,
  autoplay: {
    delay: 5000,
  },

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  breakpoints: {
    // when window width is >= 768px
    768: {
      slidesPerView: 1.2,
      spaceBetween: 10,
    }
  }
})