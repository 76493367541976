var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.student_courses.length > 0
    ? _c("div", { staticClass: "widget mini-messages" }, [
        _c("div", { staticClass: "widget__title" }, [
          _vm._v("さいきんとどいたおてがみ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget__inner" }, [
          _c(
            "ul",
            { staticClass: "mini-message_list" },
            _vm._l(_vm.student_courses, function(student_course) {
              return _c("li", [
                _c("div", { staticClass: "mini-message" }, [
                  _c("div", { staticClass: "mini-message__course" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "/courses/" + student_course.course.id }
                      },
                      [_vm._v(_vm._s(student_course.course.title))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mini-message__img" }, [
                    _c("img", {
                      attrs: {
                        src: student_course.message_image.url,
                        width: "100"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mini-message__student" }, [
                    _c("img", {
                      attrs: {
                        src: student_course.student.icon.url,
                        width: "20"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/students/" + student_course.student.login_name
                        }
                      },
                      [_vm._v(_vm._s(student_course.student.screen_name))]
                    )
                  ])
                ])
              ])
            })
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7693bd9c", { render: render, staticRenderFns: staticRenderFns })
  }
}