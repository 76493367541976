var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.information_message
    ? _c("div", { staticClass: "widget mini-student" }, [
        _c("div", { staticClass: "widget__title" }, [
          _vm._v("おやこやひろばピックアップ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget__inner" }, [
          _c(
            "a",
            { attrs: { href: "/informations/" + _vm.information_message.id } },
            [
              _c("img", {
                attrs: {
                  src: _vm.information_message.og_image_url,
                  width: "100%"
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.information_message.title))])
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8e178e36", { render: render, staticRenderFns: staticRenderFns })
  }
}