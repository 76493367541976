












import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({

 data() {
    return {
      information_messages: [],
      information_message: null
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get(`/api/informations/hiroba_informations`).then( res =>  {
        this.information_messages = res.data.information_messages;
        this.information_message = res.data.information_messages[Math.floor(Math.random() * this.information_messages.length)];
      });
    }
  }
});
