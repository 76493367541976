



























import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      student_courses: []
    }
  },
  mounted() {
    this.get_student_courses();
  },
  methods: {
    get_student_courses() {
      let self = this;
      axios.get("/api/courses/recent_image_messages").then( res =>  {
        self.student_courses = res.data.student_courses;
      });
    }
  }
});
