














































































import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      friend_assignments: [],
      friend_articles: [],
      friend_exams: []
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let self = this;
      axios.get("/api/mypage/friends/friends_activities").then( res =>  {
        self.friend_assignments = res.data.friend_assignments;
        self.friend_articles = res.data.friend_articles;
        self.friend_exams = res.data.friend_exams;
      });
    }
  }
});
