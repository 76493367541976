var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.courses.length > 0
    ? _c("div", { staticClass: "widget ranking" }, [
        _c("div", { staticClass: "widget__title" }, [
          _vm._v("あたらしいぼうけん")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget__inner" }, [
          _c(
            "ul",
            { staticClass: "ranking__list" },
            _vm._l(_vm.courses, function(course) {
              return _c("li", [
                _c(
                  "span",
                  { class: "category-color-" + course.category.code },
                  [_vm._v("●")]
                ),
                _c("a", { attrs: { href: "/courses/" + course.id } }, [
                  _vm._v(_vm._s(course.title))
                ])
              ])
            })
          ),
          _vm._v(" "),
          _vm._m(0)
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "more" }, [
      _c(
        "a",
        {
          staticClass: "mod_btn-more",
          attrs: { href: "/courses/recent_published_courses" }
        },
        [_vm._v("もっとみる")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9760a8aa", { render: render, staticRenderFns: staticRenderFns })
  }
}