var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.students.length > 0
    ? _c("div", { staticClass: "widget mini-student" }, [
        _c("div", { staticClass: "widget__title" }, [
          _vm._v("ぼうけんしょしんしゃ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget__inner" }, [
          _c(
            "ul",
            { staticClass: "item-list" },
            _vm._l(_vm.students, function(student) {
              return _c("li", [
                _c("div", { staticClass: "item-header" }, [
                  _vm._v(_vm._s(student.screen_name))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-image" }, [
                  _c("img", {
                    attrs: { src: student.icon.small.url, width: "50" }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-more" }, [
                  _c(
                    "a",
                    {
                      staticClass: "mod_btn-more btn-s",
                      attrs: { href: "/students/" + student.login_name }
                    },
                    [_vm._v("しまを見る")]
                  )
                ])
              ])
            })
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6abe3ec3", { render: render, staticRenderFns: staticRenderFns })
  }
}