


















import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      recent_heart_up_courses: []
    }
  },
  mounted() {
    this.get_recent_heart_up_courses();
  },
  methods: {
    get_recent_heart_up_courses() {
      let self = this;
      axios.get("/api/courses/recent_heart_up_courses",{params: { limit: 5 } }).then( res =>  {
        self.recent_heart_up_courses= res.data.courses;
      });
    }
  }
});
