















































import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      friend_login_name: '',
      friend_count: 0,
      is_max_friend: false,
      on_not_found: false,
      invitations: []
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let self = this;
      axios.get("/api/mypage/friends/friend_info").then( res =>  {
        self.friend_count = res.data.friend_count;
        self.is_max_friend = res.data.is_max_friend;
        self.invitations = res.data.invitations;
      });
    },
    search() {
      this.on_not_found = false;
      axios.post(`/api/students/check_login_name`, { login_name: this.friend_login_name  }).then( (res) => {
        if ( res.data.available ) {

          this.on_not_found = true;
        }
        else {
          location.href = `/students/${this.friend_login_name}`;
        }
      })
      ;

    },
    accept(id) {
      axios.post(`/api/mypage/friends/accept/${id}/`).then( (res) => {
        this.getData();
      });

    },
    reject(id) {
      axios.post(`/api/mypage/friends/reject/${id}/`).then( (res) => {
        this.getData();
      });
    },
  }
});
