




















import axios from 'axios';
import Vue from 'vue';
import signupWidget from './widgets/signup.vue';
import mypageWidget from './widgets/mypage.vue';
import friendWidget from './widgets/friend.vue';
import recentDoneStudentsWidget from './widgets/recent_done_students.vue';
import categoryRecentDoneStudentsWidget from './widgets/category_recent_done_students.vue';
import categoriesWidget from './widgets/categories.vue';
import categoryHeartfullTeachersWidget from './widgets/category_heartfull_teachers.vue';
import heartfullTeachersWidget from './widgets/heartfull_teachers.vue';
import securityReportWidget from './widgets/security_report.vue';
import recentHeartUpCoursesWidget from './widgets/recent_heart_up_courses.vue';
import categoryRecentHeartUpCoursesWidget from './widgets/category_recent_heart_up_courses.vue';
import recentPublishedCoursesWidget from './widgets/recent_published_courses.vue';
import categoryRecentPublishedCoursesWidget from './widgets/category_recent_published_courses.vue';
import recommendCoursesWidget from './widgets/recommend_courses.vue';
import recentMessageImagesWidget from './widgets/recent_message_images.vue';
import friendActivitiesWidget from './widgets/friend_activities.vue';
import recentNewStudentsWidget from './widgets/recent_new_students.vue';
import newbieTeachersWidget from './widgets/newbie_teachers.vue';
import hirobaWidget from './widgets/hiroba.vue';
import seriesPageWidget from './widgets/series_page.vue';

export default Vue.extend({
  data() {
    return {
      fakead: false,
      student: null,
      category_id: null,
      course_id: null,
      course_title: null,
      article_title: null,
      article_id: null,
      categories: [],
      widgetNames: [],
      campaignAdId: 0
    }
  },
  mounted() { 
    if (window["adsbygoogle"] != null){
      (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
    } else {
      this.fakead = true;
    }
    this.campaignAdId = this.getRandomInt(2);
    this.buildWidget();
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    setWidgets(widgetNames) {
      this.widgetNames = widgetNames;
    },
    getCategories() {
      axios.get("/api/categories").then( res =>  {
        this.categories = res.data.categories;
      });
    },
    buildWidget() {
      for (var widgetName of this.widgetNames) {
        if ( widgetName == 'signup' ) {
          var instance = new( Vue.extend(signupWidget));
          instance.$mount(); 
          this.$refs.side_content.appendChild(instance.$el);
        }
        else if ( widgetName == 'mypage' ) {
          var instance = new( Vue.extend(mypageWidget));
          instance.$props.student = this.student;
          instance.$mount(); 
          this.$refs.side_content.appendChild(instance.$el);
        }
        else if ( widgetName == 'friend' ) {
          var instanceZ = new( Vue.extend(friendWidget));
          instanceZ.$mount(); 
          this.$refs.side_content.appendChild(instanceZ.$el);
        }
        else if ( widgetName == 'recent_done_students' ) {
          var instanceX = new( Vue.extend(recentDoneStudentsWidget));
          instanceX.$mount(); 
          this.$refs.side_content.appendChild(instanceX.$el);
        }
        else if ( widgetName == 'recent_heart_up_courses' ) {

          var instance2 = new recentHeartUpCoursesWidget;
          instance2.$mount(); 
          this.$refs.side_content.appendChild(instance2.$el);
        }
        else if ( widgetName == 'recent_published_courses' ) {
          var instance3 = new recentPublishedCoursesWidget;
          instance3.$mount(); 
          this.$refs.side_content.appendChild(instance3.$el);
        }
        else if ( widgetName == 'recommend_courses' ) {
          var instance4 = new recommendCoursesWidget;
          instance4.$mount(); 
          this.$refs.side_content.appendChild(instance4.$el);
        }

        else if ( widgetName == 'recent_message_images' ) {
          var instance5 = new recentMessageImagesWidget;
          instance5.$mount(); 
          this.$refs.side_content.appendChild(instance5.$el);
        }
        else if (widgetName == 'security_report' ) {
          let instance6 = new (Vue.extend(securityReportWidget));
          instance6.$props.course_id  = this.course_id;
          instance6.$props.article_id = this.article_id;
          instance6.$props.course_title  = this.course_title;
          instance6.$props.article_title = this.article_title;
          instance6.$mount(); 
          this.$refs.side_content.appendChild(instance6.$el);
        }
        else if ( widgetName == 'category_heartfull_teachers' ) {
          let instance7 = new (Vue.extend(categoryHeartfullTeachersWidget));
          instance7.$props.category_id = this.category_id;
          instance7.$mount(); 
          this.$refs.side_content.appendChild(instance7.$el);
        }
        else if ( widgetName == 'category_recent_heart_up_courses' ) {
          var instance8 = new categoryRecentHeartUpCoursesWidget;
          instance8.$props.category_id = this.category_id;
          instance8.$mount(); 
          this.$refs.side_content.appendChild(instance8.$el);
        }
        else if ( widgetName == 'category_recent_published_courses' ) {
          var instance9 = new categoryRecentPublishedCoursesWidget;
          instance9.$props.category_id = this.category_id;
          instance9.$mount(); 
          this.$refs.side_content.appendChild(instance9.$el);
        }
        else if ( widgetName == 'categories' ) {
          var instance10 = new (Vue.extend(categoriesWidget));
          instance10.$mount(); 
          this.$refs.side_content.appendChild(instance10.$el);
        }
        else if( widgetName == 'category_recent_done_students' ) {
          var instance11 = new categoryRecentDoneStudentsWidget;
          instance11.$props.category_id = this.category_id;
          instance11.$mount(); 
          this.$refs.side_content.appendChild(instance11.$el);
        }
        else if ( widgetName == 'friend_activities' ) {
          var instance12 = new( Vue.extend(friendActivitiesWidget));
          instance12.$mount(); 
          this.$refs.side_content.appendChild(instance12.$el);
        }
        else if ( widgetName == 'recent_new_students' ) {
          var instance13 = new recentNewStudentsWidget;
          instance13.$mount(); 
          this.$refs.side_content.appendChild(instance13.$el);
        }
        else if ( widgetName == 'heartfull_teachers' ) {
          var instance14 = new heartfullTeachersWidget;
          instance14.$mount(); 
          this.$refs.side_content.appendChild(instance14.$el);
        }
        else if ( widgetName == 'newbie_teachers' ) {
          var instance15 = new newbieTeachersWidget;
          instance15.$mount(); 
          this.$refs.side_content.appendChild(instance15.$el);
        }
        else if ( widgetName == 'hiroba' ) {
          var instance16 = new hirobaWidget;
          instance16.$mount(); 
          this.$refs.side_content.appendChild(instance16.$el);
        }
        else if ( widgetName == 'series_page' ) {
          var instance17 = new seriesPageWidget;
          instance17.$mount(); 
          this.$refs.side_content.appendChild(instance17.$el);
        }

      }
    }
  }
});

