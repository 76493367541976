

























import axios from 'axios';
import Vue from 'vue';

export default Vue.extend({
 props: ['category_id'],
 data() {
    return {
      category: null,
      student_courses: []
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get(`/api/categories/${this.category_id}/recent_done_students`).then( res =>  {
        this.student_courses = res.data.student_courses;
        this.category = res.data.category;
      });
    }
  }
});

