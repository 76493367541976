














































































































import axios from 'axios';
import Vue from 'vue';
import course_box from './../commons/course_box.vue';
import queryString from 'query-string';

export default Vue.extend({
  components: {
    course_box
  },
  data()  {
    return  {
      current_student: null,
      recommend_courses: [],
      recent_heart_up_courses: [],
      recent_heart_up_teachers: [],
      recent_published_courses: [],
      recent_notes: [],
      current_student_info: null
    }
  }, 
  mounted() {
    this.markDoneCourses();
    this.getRecentNotes();
    this.get_recent_heart_up_courses();
    this.get_recent_heart_up_teachers();
    this.get_recommend_courses();
    this.get_recent_published_courses();
  },
  methods: {
    markDoneCourses() {
      if ( this.current_student == null ) {
        return;
      }
      let self = this;
      axios.get("/api/mypage/courses").then( res =>  {
        self.current_student_info = res.data;
      });
    },
    getRecentNotes() {
      let self = this;
      axios.get("/api/notes/recent_notes").then( res =>  {
        self.recent_notes = res.data.notes;
      });
    },
    get_recent_published_courses() {
      let self = this;
      axios.get("/api/courses/recent_published_courses",{params: { limit: 6 } }).then( res =>  {
        self.recent_published_courses = res.data.courses;
      });
    },
    get_recent_heart_up_teachers() {
      let self = this;
      axios.get("/api/teachers/recent_heart_up_teachers",{params: { limit: 10 } }).then( res =>  {
        self.recent_heart_up_teachers = res.data.teachers;
      });
    },
    get_recent_heart_up_courses() {
      let self = this;
      axios.get("/api/courses/recent_heart_up_courses",{params: { limit: 6 } }).then( res =>  {
        self.recent_heart_up_courses= res.data.courses;
      });
    },
    get_recommend_courses() {
      let self = this;
      axios.get("/api/courses/recommend_courses",{}).then( res =>  {
        self.recommend_courses = res.data.courses.slice(0,3);
      });
    }

  }
});
