var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal", attrs: { id: "security_report_done-modal" } },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("安全報告処理が完了しました")
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.modal_done.hide()
                    }
                  }
                },
                [_c("span", [_vm._v("×")])]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      _vm.modal_done.hide()
                    }
                  }
                },
                [_vm._v("閉じる")]
              )
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal", attrs: { id: "security_report-modal" } },
      [
        _c("div", { staticClass: "modal-dialog modal-security" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "edit-form" }, [
              _c("div", { staticClass: "edit-form__header" }, [
                _c("h2", { staticClass: "title" }, [
                  _vm._v("安全のための報告をする")
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.modal.hide()
                      }
                    }
                  },
                  [_c("span", [_vm._v("×")])]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "edit-form__inner" }, [
                _c("div", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.course_title) +
                      "\n              "
                  )
                ]),
                _vm._v(" "),
                _vm.article_title
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.article_title) +
                          "\n              "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "form-message" }, [
                  _vm._v("いたずらでほうこくするのはやめようね")
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    attrs: { novalidate: "true", method: "post" },
                    on: { submit: _vm.checkForm }
                  },
                  [
                    _vm.errors.length
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "mod_alert alert-danger",
                              attrs: { role: "alert" }
                            },
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _c("div", { staticClass: "secondary" }, [
                                _c("div", { staticClass: "inner" }, [
                                  _vm._m(2),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "message" }, [
                                    _c("div", { staticClass: "error-title" }, [
                                      _vm._v("以下のエラーを修正してください")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      { staticClass: "error-list" },
                                      _vm._l(_vm.errors, function(error) {
                                        return _c("li", [_vm._v(_vm._s(error))])
                                      })
                                    )
                                  ])
                                ])
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-item" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-item__main" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.name,
                              expression: "name"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "name" },
                          domProps: { value: _vm.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.name = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-item" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-item__main" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "email" },
                          domProps: { value: _vm.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-item" }, [
                      _vm._m(5),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-item__main" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.security_report_type,
                                expression: "security_report_type"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "security_report_type" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.security_report_type = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("-")
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "copyright_issue" } },
                              [_vm._v("著作権とその無断使用")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              {
                                attrs: { value: "inappropriate_for_children" }
                              },
                              [_vm._v("子供に不適切な内容")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "misc" } }, [
                              _vm._v("その他")
                            ])
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-item" }, [
                      _vm._m(6),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-item__main" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.title,
                              expression: "title"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "title" },
                          domProps: { value: _vm.title },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.title = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-item" }, [
                      _vm._m(7),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-item__main" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.message,
                              expression: "message"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { name: "message", rows: "5", cols: "20" },
                          domProps: { value: _vm.message },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.message = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(8)
                  ]
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "widget security_report" }, [
      _c("div", { staticClass: "widget__inner text-center" }, [
        _c(
          "span",
          {
            staticClass:
              "open-security_report-modal btn btn-sm btn-outline-primary"
          },
          [_vm._v("このページを"), _c("br"), _vm._v("安全のための報告をする")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "primary" }, [
      _c("img", {
        attrs: { src: "/statics/images/alert_img_1.svg", width: "108", alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: "/statics/images/alert_icon_1.svg", width: "44", alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-item__name" }, [
      _vm._v("なまえ"),
      _c("sup", [_vm._v("※")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-item__name" }, [
      _vm._v("メールアドレス"),
      _c("sup", [_vm._v("※")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-item__name" }, [
      _vm._v("違法種別"),
      _c("sup", [_vm._v("※")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-item__name" }, [
      _vm._v("タイトル"),
      _c("sup", [_vm._v("※")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-item__name" }, [
      _vm._v("内容"),
      _c("sup", [_vm._v("※")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-item__btn" }, [
      _c("div", { staticClass: "form-group text-center" }, [
        _c(
          "button",
          {
            staticClass: "mod_btn btn-normal btn-border",
            attrs: { type: "submit" }
          },
          [_vm._v("通報する")]
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4578cd1b", { render: render, staticRenderFns: staticRenderFns })
  }
}