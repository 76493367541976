












export default {
  props: []
}
