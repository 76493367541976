










export default {
  props: ['difficulty'],
  methods: {
    difficultyNum(difficulty) {
      let a = { "very_easy": 1, "easy": 2, "normal": 3,  "hard": 4, "very_hard": 5 }
      return a[difficulty];
    }
  }
}
