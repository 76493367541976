































import axios from 'axios';
import Vue from 'vue';

export default Vue.extend({
 props: ['category_id'],
 data() {
    return {
      category: null,
      teachers: []
    }
  },
  mounted() {
    this.getHeartfullTeachers();
  },
  methods: {
    getHeartfullTeachers() {
      axios.get(`/api/categories/${this.category_id}/heartfull_teachers`).then( res =>  {
        this.teachers = res.data.teachers;
        this.category = res.data.category;
      });
    }
  }
});

