




















import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({

  data() {
    return {
      students: []
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get(`/api/students/recent_new_students`).then( res =>  {
        this.students = res.data.students;
      });
    }
  }
});
