


















import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      courses: []
    }
  },
  mounted() {
    this.get_courses();
  },
  methods: {
    get_courses() {
      let self = this;
      axios.get("/api/courses/recommend_courses",{params: { limit: 5 } }).then( res =>  {
        self.courses = res.data.courses;
      });
    }
  }
});
