import Vue from 'vue';
import SidebarVue from '../../src/components/sidebar.vue';

export enum WidgetCode {
  // category ----
  Categories = "categories",
  CategoryHeartfullTeachers = "category_heartfull_teachers",
  CategoryRecentDoneStudents = "category_recent_done_students",
  CategoryRecentHeartUpCourses = "category_recent_heart_up_courses",
  CategoryRecentPublishedCourses = "category_recent_published_courses",

  // login ----
  Mypage = "mypage",
  Friend = "friend",
  FriendActivities = "friend_activities",

  // none login ---
  Signup = "signup",

  // course ----
  SecurityReport = "security_report",

  // info ----
  HeartfullTeachers  = "heartfull_teachers",
  NewbieTeachers = "newbie_teachers",
  RecentDoneStudents = "recent_done_students",
  RecentHeartUpCourses = "recent_heart_up_courses",
  RecentMessageImages = "recent_message_images",
  RecentNewStudents = "recent_new_students",
  RecentPublishedCourses = "recent_published_courses",
  RecommendCourses =  "recommend_courses",
  Hiroba = "hiroba",
  SeriesPage = "series_page"
}


export default class Sidebar {

  isLogin: boolean;
  vue: Vue;

  constructor(isLogin,student) {
    this.isLogin = isLogin;
    this.vue = new Vue(SidebarVue);
    if (this.isLogin) {
      this.vue.$data.student = student
    }
  }

  articleSidebar(categoryId, courseId, courseTitle, articleId, articleTitle) {
    this.vue.$data.article_id= articleId;
    this.vue.$data.article_title =  articleTitle;
    this.courseSidebar(categoryId, courseId, courseTitle);
  }

  courseSidebar(categoryId, courseId, courseTitle) {
    this.vue.$data.category_id = categoryId;
    this.vue.$data.course_id =  courseId;
    this.vue.$data.course_title =  courseTitle;

    if (this.isLogin) {
      this.vue.$data.widgetNames = [ WidgetCode.Mypage, WidgetCode.Categories, WidgetCode.Hiroba, WidgetCode.SeriesPage, WidgetCode.Friend, WidgetCode.FriendActivities ];
    }
    else {
      this.vue.$data.widgetNames = [ WidgetCode.Signup, WidgetCode.Categories, WidgetCode.Hiroba, WidgetCode.SeriesPage];
    }

    this.vue.$data.widgetNames.push( 
      WidgetCode.RecentDoneStudents,
      WidgetCode.RecentMessageImages,
      WidgetCode.CategoryHeartfullTeachers,
      WidgetCode.CategoryRecentPublishedCourses,
      WidgetCode.RecommendCourses,
      WidgetCode.SecurityReport
    );

    this.vue.$mount("#app-sidebar");
  }

  categorySidebar(categoryId) {
    this.vue.$data.category_id = categoryId;

    if (this.isLogin) {
      this.vue.$data.widgetNames = [ WidgetCode.Mypage, WidgetCode.Categories, WidgetCode.Hiroba, WidgetCode.SeriesPage, WidgetCode.Friend, WidgetCode.FriendActivities ];
    }
    else {
      this.vue.$data.widgetNames = [ WidgetCode.Signup, WidgetCode.Categories, WidgetCode.Hiroba, WidgetCode.SeriesPage];
    }

    this.vue.$data.widgetNames.push( 
      WidgetCode.CategoryHeartfullTeachers,
      WidgetCode.CategoryRecentDoneStudents,
      WidgetCode.CategoryRecentHeartUpCourses,
      WidgetCode.CategoryRecentPublishedCourses
    );

    this.vue.$mount("#app-sidebar");
  }
  
  setupCommonSidebar( except = [] ) {

    if (this.isLogin) {
      this.vue.$data.widgetNames = [ WidgetCode.Mypage, WidgetCode.Categories, WidgetCode.Hiroba, WidgetCode.SeriesPage, WidgetCode.Friend, WidgetCode.FriendActivities ];
    }
    else {
      this.vue.$data.widgetNames = [ WidgetCode.Signup, WidgetCode.Categories, WidgetCode.Hiroba, WidgetCode.SeriesPage ];
    }

    this.vue.$data.widgetNames.push( 
      WidgetCode.RecentDoneStudents,
      WidgetCode.RecentMessageImages,
      WidgetCode.RecentNewStudents,
      WidgetCode.NewbieTeachers,
      WidgetCode.HeartfullTeachers,
      WidgetCode.Hiroba,
      WidgetCode.SeriesPage,
      WidgetCode.RecentHeartUpCourses,
      WidgetCode.RecentPublishedCourses,
      WidgetCode.RecommendCourses
    );


    this.removewidgetName(except);
    this.vue.$mount("#app-sidebar");

  }

  removewidgetName(except){
    except.forEach( exceptName => {
      this.vue.$data.widgetNames.forEach( (name, index) => {
        if(name === exceptName) this.vue.$data.widgetNames.splice(index,1);
      });
    });
  }


  setupTopSidebar() {
    if (this.isLogin) {
      this.vue.$data.widgetNames = [ WidgetCode.Mypage, WidgetCode.Categories, WidgetCode.Hiroba, WidgetCode.SeriesPage, WidgetCode.Friend, WidgetCode.FriendActivities ];
    }
    else {
      this.vue.$data.widgetNames = [ WidgetCode.Signup, WidgetCode.Categories, WidgetCode.Hiroba, WidgetCode.SeriesPage];
    }

    this.vue.$data.widgetNames.push( 
      WidgetCode.RecentDoneStudents,
      WidgetCode.RecentMessageImages,
      WidgetCode.RecentNewStudents,
      WidgetCode.NewbieTeachers
    );

    this.vue.$mount("#app-sidebar");
  }

}
