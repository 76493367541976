












import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({

 data() {
    return {
      series_pages: [],
      series_page: null
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get(`/api/series_pages`).then( res =>  {
        this.series_pages = res.data.series_pages;
        this.series_page = res.data.series_pages[Math.floor(Math.random() * this.series_pages.length)];
      });
    }
  }
});
