
































export default {
  props: ['student']
}
