















import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
 props: ['category_id'],
  data() {
    return {
      category: null,
      recent_heart_up_courses: []
    }
  },
  mounted() {
    this.get_recent_heart_up_courses();
  },
  methods: {
    get_recent_heart_up_courses() {
      axios.get(`/api/categories/${this.category_id}/recent_heart_up_courses`).then( res =>  {
        this.recent_heart_up_courses = res.data.courses;
        this.category = res.data.category;
      });
    }
  }
});
