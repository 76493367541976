var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.teachers.length > 0
    ? _c("div", { staticClass: "widget mini-teachers" }, [
        _c("div", { staticClass: "widget__title" }, [
          _vm._v("しんいりせんせい")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget__inner" }, [
          _c(
            "ul",
            { staticClass: "mini-teacher-list" },
            _vm._l(_vm.teachers, function(teacher) {
              return _c("li", [
                _c("div", { staticClass: "mini-teacher" }, [
                  _c("div", { staticClass: "mini-teacher__icon" }, [
                    _c("div", { staticClass: "mini-teacher__image" }, [
                      _c("a", { attrs: { href: "/teachers/" + teacher.id } }, [
                        _c("img", {
                          attrs: { src: teacher.icon.small.url, width: "120" }
                        })
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mini-teacher__body" }, [
                    _c("div", { staticClass: "mod_bln bln-top bln-blue" }, [
                      _c("div", { staticClass: "mini-teacher__name" }, [
                        _c(
                          "a",
                          { attrs: { href: "/teachers/" + teacher.id } },
                          [_vm._v(_vm._s(teacher.name))]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            })
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-350e1f1a", { render: render, staticRenderFns: staticRenderFns })
  }
}