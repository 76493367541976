



























































import difficulty_monstars from './difficulty_monstars.vue';

export default {
  props: ['course','current_student_info'],
  components: {
    difficulty_monstars
  },
  methods: {
    course_path(id){ 
      return "/courses/" + id ;
    },

    studeint_progress(){

      if ( this.current_student_info == null ) {
        return 'none';
      }

      let info = this.current_student_info;

      if ( info.done_course_hash_ids[this.course.id] ) {
          return 'done';
      }

      if ( info.playing_course_hash_ids[this.course.id] ) {
          return 'playing';
      }

      return 'none';

    }

  }
}
