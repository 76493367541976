














import axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
//  props: ['categories']
 data() {
    return {
      categories: []
    }
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      axios.get("/api/categories").then( res =>  {
        this.categories = res.data.categories;
      });
    }
  }
});
