var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "difficulty_monstars" }, [
    _c("img", {
      attrs: {
        src: "/statics/images/difficulty/difficulty_very_easy.svg",
        height: "26"
      }
    }),
    _vm._v(" "),
    _vm.difficultyNum(_vm.difficulty) > 1
      ? _c("img", {
          attrs: {
            src: "/statics/images/difficulty/difficulty_easy.svg",
            height: "30"
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.difficultyNum(_vm.difficulty) > 2
      ? _c("img", {
          attrs: {
            src: "/statics/images/difficulty/difficulty_normal.svg",
            height: "34"
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.difficultyNum(_vm.difficulty) > 3
      ? _c("img", {
          attrs: {
            src: "/statics/images/difficulty/difficulty_hard.svg",
            height: "38"
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.difficultyNum(_vm.difficulty) > 4
      ? _c("img", {
          attrs: {
            src: "/statics/images/difficulty/difficulty_very_hard.svg",
            height: "43"
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c0a40bcc", { render: render, staticRenderFns: staticRenderFns })
  }
}